
  
    @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;600&display=swap');

  
    

    .modal {
      font-size: 12px;
      
   
      max-width: 100% !important;
	    background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: rgb(6 20 30 / 81%);
      border:none !important;
      border-radius: 10px;
    
       font-family: 'Orbitron', sans-serif !important;
      
     
    }
    .faqgrid1{
      background-image: none !important;
    }

    .faqgrid2{
      background-image: none !important;
    }

    .css-1c6qdv5{

      display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: 1.2;
    border-radius: var(--chakra-radii-md);
    font-weight: var(--chakra-fontWeights-semibold);
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    height: var(--chakra-sizes-10);
    min-width: var(--chakra-sizes-10);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    border: var(--chakra-borders-2px);
    font-size: 20px;
    color: #E9D8FD;
    }

    .css-n23sg9{
      animation: rotateY 3s infinite !important;

    }

    .css-sy770q {
      background: transparent !important;
      
      
    }

    .css-1viq5e2{
      background: transparent !important;
      color: var(--chakra-colors-white);
    }

    .css-1sgs4n2{
      outline: 2px solid #26b33d41 !important;
      border-width: 0px !important;

      background: #202020d6 !important;
    }
    .ju367vex {
      color: rgb(1 237 236) !important;
    }

    .ju367vb0 {
      background: #000000 !important;
      outline: 2px solid #002fffbd !important;
    }
   
    .ju367vbl{
      background: transparent !important;
    }

    .css-11a8r05{
      --card-bg: transparent !important;
      box-shadow: 10px 10px 50px 10px rgba(1, 213, 255, 0.18) inset !important;
      background-color: #66ff0023 !important;
      background-image: url("../Images/bg1.png");
      background-size: cover !important;
 
      
     
      border-color: rgb(1, 237, 135) !important;
     
    
     
    }

    .css-18cq000 {
      background-color: transparent !important;
      max-width: fit-content !important;
     
    }

    .css-1xqfl3j{
      max-width: fit-content !important;
      
    }

    .css-1ieca2l{
      max-width: fit-content !important;
      margin: auto !important;
    }

    .css-1fhwv3r{
      align-items: center !important;
    }
    .css-tfghhi{
      justify-items: center !important;
    }
    .css-1idwstw{
      display: flex !important;
      justify-content: center !important;
      
    }
    .css-1lekzkb{
      display: block !important;
    }
    .css-1xqfl3j{
      margin: auto !important;
    }



    .popup-content {
      background-image: url("/src/Images/hacker\ gif.gif") !important;
      background-repeat: no-repeat !important;
      
      background-size: cover !important;
      
  
      border:none !important;
      border-radius: 20px;
      width: 85%;
      margin: auto;
  
      box-shadow:
      inset 0 0 20px rgba(1, 46, 42, 0),      /* inner white */
  inset 20px 0 30px rgba(1, 46, 42, 0),   /* inner left magenta short */
  inset -20px 0 30px rgb(1, 46, 42),  /* inner right cyan short */
  inset 20px 0 100px rgba(1, 46, 42, 0),  /* inner left magenta broad */
  inset -20px 0 100px rgb(1, 46, 42), /* inner right cyan broad */
  0 0 1px rgb(0, 200, 255),            /* outer white */
  -10px 0 10px rgb(0, 132, 149),        /* outer left magenta */
  10px 0 10px rgb(0, 255, 255);         /* outer right cyan */
    }

    .form-control{
      border-radius: 30px;
      
      font-size: 14px;
      background: #72727257 !important;
      width: 70%;
      padding: 7px 5px;
      margin: auto;
      text-align: center;
      font-weight: 900 !important;
      color: #ffffff !important;
      font-family: 'Poppins', sans-serif !important;

      
    }

    
    
    .modal > .content {
      width: 100%;
      padding: 10px 5px;
      color:#cfcece;
      font-weight: bold;
      background-color: #050505c0 !important;
      border:none !important;
      font-family: 'Orbitron', sans-serif ;
      
    }
    .css-tfghhi{
      background-color: #000000ea;
      border-radius: 30px;
      padding-top: 15px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-bottom: 15px !important;
    }
    .modal > .actions {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
    }

    .css-f31npf{

      border-radius: 30px !important;
      border-color: #13ffac90 !important;
      font-size: 15px !important;
    }


    .modal > .headerMD {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
      font-size: large;
      color: #0ff;
      font-size: 20px !important;
    }

    .modal > .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #cfcece;
    }

h1,h2,h3{
  font-family: 'hacked-kerX' !important;
}

/**
  * Meetup
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes meetup {
    0%, 100% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
    50% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
  @keyframes meetup {
    0%, 100% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
    50% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
  
  .meetup {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    height: 10px;
    width: 10px; }
    .meetup div {
      height: 100%;
      position: absolute;
      width: 100%;
      -webkit-animation: meetup 1.25s infinite ease;
              animation: meetup 1.25s infinite ease;
      background: rgb(7, 7, 7);
      border-radius: 100%; }
      .meetup div:nth-child(1) {
        --rotation: 90; }
      .meetup div:nth-child(2) {
        --rotation: 180; }
      .meetup div:nth-child(3) {
        --rotation: 270; }
      .meetup div:nth-child(4) {
        --rotation: 360; }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }





              body {
                background-image: linear-gradient( 190deg, #0c0c0e 0%, #161a1e 29%, #09153f 67%, #002c80 100% ) !important;
                background-clip: border-box !important;
                
                
                 margin: auto;
                font-family: 'Orbitron', sans-serif !important;
                overflow: auto;
               
                background-size: 100% 100%;
                background-attachment: fixed;
                
               
              
            }
            
            @keyframes gradient {
                0% {
                    background-position: 0% 0%;
                }
                50% {
                    background-position: 100% 100%;
                }
                100% {
                    background-position: 0% 0%;
                }
            }
            
            .wave {
                background-color: linear(45deg, #008be7, #00cce3);
                border-radius: 1000% 1000% 0 0;
                position: fixed;
                width: 200%;
                height: 12em;
                animation: wave 10s -3s linear infinite;
                transform: translate3d(0, 0, 0);
                opacity: 0.8;
                bottom: 0;
                left: 0;
                z-index: -1;
            }
            
            .wave:nth-of-type(2) {
                bottom: -1.25em;
                animation: wave 18s linear reverse infinite;
                opacity: 0.8;
                color:rgb(0, 132, 255)
            }
            
            .wave:nth-of-type(3) {
                bottom: -2.5em;
                animation: wave 20s -1s reverse infinite;
                opacity: 0.9;
                color:rgb(0, 247, 255)
            }


            
            @keyframes wave {
                2% {
                    transform: translateX(1);
                }
            
                25% {
                    transform: translateX(-25%);
                }
            
                50% {
                    transform: translateX(-50%);
                }
            
                75% {
                    transform: translateX(-25%);
                }
            
                100% {
                    transform: translateX(1);
                }
            }
            .colored-toast.swal2-icon-success {
              background-color: #000000 !important;
            }
            
            .colored-toast.swal2-icon-error {
              background-color: #000000 !important;
            }
            
            .colored-toast.swal2-icon-warning {
              background-color: #000000 !important;
            }
            
            .colored-toast.swal2-icon-info {
              background-color: #000000 !important;
            }
            
            .colored-toast.swal2-icon-question {
              background-color: #000000 !important;
            }
            
            .colored-toast .swal2-title {
              color: rgb(101, 101, 101);
            }
            
            .colored-toast .swal2-close {
              color: rgb(47, 47, 47);
            }
            
            .colored-toast .swal2-html-container {
              color: rgb(27, 27, 27);
            }

            .swal2-styled.swal2-confirm {
              border: 0;
              border-radius: 0.25em;
              background: initial;
              background-color: #2624f3 !important;
              color: #fff;
              font-size: 1em;
          }

            .popup-overlay {
              background: rgba(26, 36, 37, 0);
              overflow: scroll !important;
            }

            .swal2-container{
              background: rgba(26, 36, 37, 0) !important;
              overflow: scroll !important;
            }

            .swal2-html-container, .swal2-title{
             color: #2624f3 !important;
             font-size: 16px !important;
             
              font-family: 'Orbitron', sans-serif !important;
            }

            .css-15z3m5c{
              border-color: #8ec6ff !important;
            }

            .coldrsp{
              font-family: 'Orbitron', sans-serif ;
              font-size: medium;
            }
            .swal2-fire, .swal2-title{
              text-transform: uppercase;
              color: #fff !important;

      font-size: 30px;;
            }
            .swal2-border-radius { -webkit-border-radius: 40 !important; -moz-border-radius: 40 !important; border-radius: 40 !important; }
.lgl{
  -webkit-animation: rotateY 3s infinite;
  -moz-animation: rotateY 3s infinite;
  -o-animation: rotateY 3s infinite;
  animation: rotateY 3s infinite;
  color: rgb(3, 170, 131);
  background-color: #0765004b;
  border-radius: 25px;
 
}

/* animation */
@-webkit-keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@-o-keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }}

@-webkit-keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@-o-keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}

}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
}
25% {
    transform: rotateY(0deg);
}
75% {
    transform: rotateY(360deg);
}
100%{
    transform: rotateY(360deg);
}
}



.sideimg{
  animation: rotateY 5s infinite;
  border-radius: 30px;
  border: 5px solid #555;
  border-color: #00ffea;
  

  box-shadow:
  inset 0 0 20px rgb(1, 46, 42),      /* inner white */
  inset 20px 0 30px rgb(1, 46, 42),   /* inner left magenta short */
  inset -20px 0 30px rgb(1, 46, 42),  /* inner right cyan short */
  inset 20px 0 100px rgb(1, 46, 42),  /* inner left magenta broad */
  inset -20px 0 100px rgb(1, 46, 42), /* inner right cyan broad */
  0 0 1px rgb(0, 200, 255),            /* outer white */
  -10px 0 10px rgb(0, 149, 124),        /* outer left magenta */
  10px 0 10px rgb(0, 255, 255);         /* outer right cyan */
}

.cardbody{
  border-color: rgb(1, 237, 135) !important;
  box-shadow:
  inset 0 0 20px rgb(1, 46, 42),      /* inner white */
  inset 20px 0 30px rgb(1, 46, 42),   /* inner left magenta short */
  inset -20px 0 30px rgb(1, 46, 42),  /* inner right cyan short */
  inset 20px 0 100px rgb(1, 46, 42),  /* inner left magenta broad */
  inset -20px 0 100px rgb(1, 46, 42), /* inner right cyan broad */
  0 0 1px rgb(0, 200, 255),            /* outer white */
  -10px 0 10px rgb(0, 149, 124),        /* outer left magenta */
  10px 0 10px rgb(0, 255, 255);         /* outer right cyan */
  max-width: fit-content !important;

}




.css-8pbt74{
  background: #00000000 !important;
}
.css-iquc7e{
  background: transparent !important;
  border: #ffffff;
  border-width: 10px;
  
}
.chakra-card__footer{
background: transparent !important;
}

.css-1trxw3w{
  background: transparent !important;
}
.css-og0ais{
  background-image: url("/src/Images/hacker\ gif.gif");
  background-size: cover;
  background-repeat: no-repeat !important;
  border-radius: 30px !important;
}

.css-mucoyu{
  background-image: url(/src/Images/hacker\ gif.gif) !important;
  color: #fff;
  
}
.css-11tmun9{
  height: auto !important;
}

.css-i857na{
  background-image: url(/src/Images/hacker\ gif.gif) !important;
  color: #fff;
  
}

.css-1kf4goo{
  background-image: url(/src/Images/hacker\ gif.gif) !important;
  color: #fff;
}

.css-cbyey{
  border-color: rgb(1, 237, 135) !important;
  background-image: url("/src/Images/mainbg.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  box-shadow:
  inset 0 0 20px rgba(0, 71, 104, 0.61),      /* inner white */
  inset 20px 0 30px rgb(0, 71, 104, 0.61),   /* inner left magenta short */
  inset -20px 0 30px rgb(0, 71, 104, 0.61),  /* inner right cyan short */
  inset 20px 0 100px rgb(0, 71, 104, 0.61),  /* inner left magenta broad */
  inset -20px 0 100px rgb(0, 71, 104, 0.61), /* inner right cyan broad */
  0 0 1px rgba(0, 30, 255, 0.448),            /* outer white */
  -10px 0 10px rgba(0, 255, 153, 0.515),        /* outer left magenta */
  10px 0 10px rgba(0, 191, 255, 0.679);         /* outer right cyan */
}


 
.css-19c8aov{
  
  background-color: transparent !important; 
  color: #0ff;
  border-radius: 30px;
  border-color: #cfcece;
  box-shadow :
  inset 0 0 20px rgb(0, 71, 104),      /* inner white */
  inset 20px 0 30px rgb(0, 71, 104),   /* inner left magenta short */
  inset -20px 0 30px rgb(0, 71, 104),  /* inner right cyan short */
  inset 20px 0 100px rgb(0, 71, 104),  /* inner left magenta broad */
  inset -20px 0 100px rgb(0, 71, 104), /* inner right cyan broad */
  0 0 1px rgb(0, 30, 255),            /* outer white */
  -10px 0 10px rgb(0, 255, 153),        /* outer left magenta */
  10px 0 10px rgb(0, 191, 255);         /* outer right cyan */

 

}

.css-ipf879{
  background: transparent !important;
}

.css-1r2z5bp{
  text-align: center !important;
}

.nft-container {
  position: relative;
}

.nft-image {
  border-radius: 30px;
  border: 4px solid #00e1ff73;
  opacity: 0.90;
  transform: scale(0.77);
  transition: transform 1.2s ease-in-out, opacity 0.2s ease-in-out;
}

.leadcard{
 
 background-image: url('/src/Images/btnbg.gif');
}

.nft-image-lead {
  border-radius: 30px;
  border: 4px solid #00e1ff73;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  

}

.nft-container:hover .nft-image {
  transform: scale(1);
  opacity: .85;
}

.nft-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 97, 119, 0.5);
  opacity: 0;
  transition: opacity 1.2s ease-in-out;
}

.nft-container:hover .nft-overlay {
  opacity: .45;
}


.cardtxt{
  text-align: center !important;
  font-family: hacked-kerX !important;
  font-weight: 300;

   text-transform: uppercase;
   
   
   background-image: linear-gradient(
    -225deg,
    #96deff 0%,
    #4699ff 29%,
    #00a5d3 67%,
    #00fff7 100%
  );
  
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
      font-size: 20px;
      padding: 15px;
      
}

.cardtxtdao{
  text-align: center !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #fff !important;
  
      
}

.css-d755lw{
  font-size: 25px !important;
}
.css-1xyobum{
  border-radius: 30px !important;
  border-color: #13ffac90 !important;
  font-size:  15px !important;
}




@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


.css-y4d98c{
  border-color: rgb(0, 255, 251) !important;;
  background: #2222226b !important;
  box-shadow:
  inset 0 0 20px rgba(0, 71, 104, 0.61),      /* inner white */
  inset 20px 0 30px rgb(0, 71, 104, 0.61),   /* inner left magenta short */
  inset -20px 0 30px rgb(0, 71, 104, 0.61),  /* inner right cyan short */
  inset 20px 0 100px rgb(0, 71, 104, 0.61),  /* inner left magenta broad */
  inset -20px 0 100px rgb(0, 71, 104, 0.61), /* inner right cyan broad */
  0 0 1px rgba(0, 30, 255, 0.448),            /* outer white */
  -10px 0 10px rgba(0, 255, 153, 0.515),        /* outer left magenta */
  10px 0 10px rgba(0, 191, 255, 0.679);         /* outer right cyan */

  display: flex !important;
  
  
}

.css-1dhyq3r{
  display:flex !important;
  min-width: 300px;
}
.css-73hz55{
  font-size: 20px !important;
}

.css-1tccf60{
  color: #ffffff !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px !important;
  font-weight: 900 !important;
 
  
}

.leadertxt{
  color: #efefef !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  max-width: 70%;

}

.headertxt{

  color: #0095ff;
}

.cardtxt1{
  text-align: center !important;
  font-family: hacked-kerX !important;
  font-weight: 300;

   text-transform: uppercase;
   
   
  background-image: linear-gradient(
    -225deg,
    #005177 0%,
    #0061d8 29%,
    #0089d3 67%,
    #00ffe5 100%
  );
  
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
      font-size: 30px;
}



.txtinbar{
  
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #005177 0%,
    #0061d8 29%,
    #0089d3 67%,
    #00ffe5 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
      font-size: 23px;
}

.css-6xix1i {
  font-size: 16px;
  padding-inline-start: 13px;
  padding-inline-end: 13px;
}

.css-7zuyd8 {
  color: var(--chakra-colors-whiteAlpha-800);
  font-size: 16px;
  padding-inline-start: 13px;
  padding-inline-end: 13px;
}
.css-baklnt{
font-family: 'Orbitron', sans-serif !important;
}




.btntxt{
  border: 2px !important;
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #2fd2fb 0%,
    #13ffac 29%,
    #0084ff 67%,
    #00ff2a 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
      font-size: 18px;

}


.nftMint{
  position: relative;
  text-transform: uppercase;
  background-image: linear-gradient( 338deg, #7581b9 0%, #2f7ed4 29%, #3261ff 67%, #2d76ff 100% );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  font-family: 'Orbitron', sans-serif !important;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
      font-size: 18px !important;
     
    

}

.ju367vfo{
  color: #0381ff !important;
  font-family: 'Orbitron', sans-serif !important;
}
.css-1rprupt {
  font-size: 24px !important;
  
}






.css-1kp110w{
  color: #89b7ef !important;
  font-size: 16px !important;
  font-family: 'Orbitron', sans-serif !important;
}

.ju367vgu {
  font-family: 'Orbitron', sans-serif !important;
}


.ju367v2p{
  color: #89b7ef !important;
  font-size: 16px !important;
  font-family: 'Orbitron', sans-serif !important;
}
.ju367vex {
  
  color: #89b7ef !important;
  font-size: 16px !important;
  font-family: 'Orbitron', sans-serif !important;
}
.rk_profile_title{
  font-size: 16px !important;
  color: #89b7ef !important;
  font-family: 'Orbitron', sans-serif !important;
}
.iekbcc0,
.ju367vgo,
.ju367v11,
.ju367v16,
.ju367v1d,
.ju367v2q {
  font-size: 16px !important;
  color: #89b7ef !important;
  font-family: 'Orbitron', sans-serif !important;
}
.css-sy770q{
  font-size: 16px !important;
  color: #89b7ef !important;
  font-family: 'Orbitron', sans-serif !important;
}
.ju367vgu {
  font-size:16px !important;
  color: #89b7ef !important;
  font-family: 'Orbitron', sans-serif !important;
}


.nftMint1{
  position: relative;
  text-transform: uppercase;
  background-image: linear-gradient( 338deg, #7581b9 0%, #2f7ed4 29%, #3261ff 67%, #2d76ff 100% );
  background-size: auto auto;
  font-family: 'Orbitron', sans-serif !important;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
      font-size: 16px;
      max-width: 450px !important;
     

}
.css-sy770q {
  background: transparent !important;
  color: #8fc5ff !important;
  outline: 2px solid #2724f3 !important;
}
.css-baklnt {
  background: transparent !important;
  color: #8fc5ff !important;
  outline: 2px solid #2724f3 !important;

}
.counter1{
  border-color:#8fc5ff !important;

}
.css-1kp110w {
  outline:2px solid #2624f3 !important;
}


.css-flzx43{
  background-image: linear-gradient( 338deg, #7581b9 0%, #2f7ed4 29%, #3261ff 67%, #2d76ff 100% ) !important;
  background-size: auto auto !important;
  background-clip: border-box !important;
  background-size: 200% auto !important;
}
.css-td8gbm{
  background-image: linear-gradient( 338deg, #7581b9 0%, #2f7ed4 29%, #3261ff 67%, #2d76ff 100% ) !important;
  background-size: auto auto !important;
  background-clip: border-box !important;
  background-size: 200% auto !important;

}

.swal2-popup{
  border:none !important;
  border-radius: 30px !important;
  background-color: #000;

 


}

.btnlabel{
  font-size: 15px !important;
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #2fb4fb 0%,
    #003e77 29%,
    #13efff 67%,
    #0066ff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  font-weight:900;

}

.btnsubmit{
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #2fb4fb 0%,
    #00e1ff 29%,
    #13ffd8 67%,
    #002fff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  margin-left: auto !important;
    margin-right: auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 20px !important;
  
}


.element.style{
  position: none !important;
}

.css-1uqpm9{
  font-size: 13px !important;
  font-weight:bolder !important;
  border-radius: 30px !important;
  
  

}


























.css-9c7r58{
  font-size: 20px !important;
}

.css-bv57s4{
  border-color: #0163ed!important;
}


.DaoHeader{
  background-image: url(../Images/darkgif.gif) !important;
}

.DaoHeaderfaq{
  background-color: transparent !important;
}

.heading{
  text-align: center !important;
  font-family: hacked-kerX !important;
  font-weight: 300;

   text-transform: uppercase;
   
   
  background-image: linear-gradient(
    -225deg,
    #003877 0%,
    #00d8d1 29%,
    #070086 67%,
    #00fff2 100%
  );
  
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
      font-size: 28px;
}

.css-lg2zl1{
  color: #13ff55 !important;
}

.css-m5k0pr{
font-size: 25px !important;
color: var(--chakra-colors-white) !important;
-webkit-padding-start: 15px !important;
padding-inline-start: 15px !important;
-webkit-padding-end: 15px !important;
padding-inline-end: 15px !important;
}
.svg1 {
	font-family: "Orbitron", sans-serif;
	width: 100%; height: 100%;
}
svg text {
	animation: stroke 5s infinite alternate;
	stroke-width: 2;
	stroke: #E6DB74;
	font-size: 75px;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
	svg text {
		font-size: 50px;
	}
}
@keyframes stroke {
	0%   {
		fill: rgba(47,95,191,0); stroke: rgb(10, 0, 124);
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
	70%  {fill: rgba(47,95,191,0); stroke: #ffffff; }
	80%  {fill: rgba(47,95,191,0); stroke: #ffffff; stroke-width: 3; }
	100% {
		fill: rgba(47,95,191,1); stroke: rgba(230,219,116,0);
		stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
	}
}



.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed or use a specific height for the wrapper */
}

/* Additional styles for the SVG text */
.wrapper svg {

  background: linear-gradient(315deg, rgb(19, 19, 19) 3%, rgba(21, 0, 103, 0.863) 38%, rgba(0, 16, 104, 0.842) 68%, rgba(0, 0, 0, 0.795) 98%);
    background-size: 100% 100%;
    background-attachment: fixed;; /* Add background color if necessary */
}

